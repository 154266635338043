export const GenderTypeEnum = {
  MASCULINO: 'MASCULINO',
  FEMININO: 'FEMININO',
  OUTROS: 'OUTROS',
};

export const PropertyClassViewTypeEnum = Object.freeze({
  LIST: 'LIST',
  PICTOGRAM: 'PICTOGRAM',
});

export const ModalIdsEnum = Object.freeze({
  DISABLE_VARIATION: 'disable-variation',
  DELETE_CUSTOMER: 'delete-customer',
  REPLACE_EMAIL: 'replace',
  DELETE_MANAGER: 'delete-manager',
  DELETE_PROPERTIE: 'delete-propertie',
  DELETE_ATTRIBUTE: 'delete-attribute',
  DELETE_COMPARATIVE: 'delete-comparative',
  DELETE_ATTRIBUTE_ALIAS: 'delete-attribute-alias',
  UNLINK_VARIATION: 'unlink-variation',
  EDIT_VARIATION_IMAGES: 'edit-varation-images',
  PRODUCT_KIT: 'product-kit',
  DELETE_ORDINATION_DISCOUNT: 'delete-ordination-discount',
  DELETE_CATEGORY_PROPERTIE: 'delete-category-propertie',
  DELETE_COMPARATIVE_ITEM: 'delete-comparative',
  DELETE_PRODUCT_ALTERNATIVE: 'delete-product-alternative',
  DELETE_PRODUCT_KIT: 'delete-product-kit',
  DELETE_IMAGE_CREATE_BANNER: 'delete-image-create-banner',
  DELETE_IMAGE_UPDATE_BANNER: 'delete-image-update-banner',
  VIEW_IMAGE_CREATE_BANNER: 'view-image-create-banner',
  VIEW_IMAGE_UPDATE_BANNER: 'view-image-update-banner',
  DELETE_PROMOTION: 'delete-promotion-modal',
  DELETE_SHIPPING_COMPANY: 'delete-shipping-company-modal',
  DELETE_ZIP_CODE_RANGE: 'delete-zip-code-range-modal',
  DELETE_WEIGHT_RANGE: 'delete-weight-range-modal',
  DELETE_ADMIN: 'delete-administrator',
  DELETE_IMAGE_VARIATION_ASSOCIATED: 'delete-imagem-variation-associated',
  REVERSAL_VALUE: 'reversal-value-modal',
  IS_ALL_PRODUCTS_CAMPAIGN: 'is-all-products-campaign',
  DELETE_COMPONENT_HOME_STORE: 'delete-component-home-store',
  VIEW_COMPONENT_HOME_STORE: 'view-component-home-store',
  DELETE_COMPONENT_MOBILE_HOME_STORE: 'delete-component-mobile-home-store',
  VIEW_COMPONENT_MOBILE_HOME_STORE: 'view-component-mobile-home-store',
  DELETE_PRODUCT_HOME_STORE: 'delete-product-home-store',
  DELETE_NEWSLETTER: 'delete-newsletter-modal',
  UPDATE_PRODUCTS_ELASTIC :'update-products-elastic',
  INACTIVE_MERCHANT:'inactive-merchant',
  ACTIVE_MERCHANT: 'active-merchant',
  INACTIVE_PRODUCTS_AND_FATHER: 'inactive-products-and-father',
  ACTIVE_PRODUCTS_AND_FATHER: 'active-products-and-father',
  REDIRECT_CATEGORY : 'redirect-category'
});

export const CategoriesEnum = Object.freeze({
  COLECAO: 'COLECAO',
  VITRINE: 'VITRINE',
});

export const MerchantEnum = Object.freeze({
  STOOM_MERCHANT: 'Merchant Stoom',
  LUMINI_MERCHANT: 'Merchant Lumini',
  SPORTBAY_MERCHANT: 'Merchant Default',
  INSUMOS_HOLAMBRA_MERCHANT: 'Merchant Insumos Holambra',
});

export const ProductTypeEnum = {
  MODEL: 'MODEL',
  VARIATION: 'VARIATION',
  PART: 'PART',
};

export const ComparativeTypeEnum = {
  TEXT: 'TEXT',
  BOOLEAN: 'BOOLEAN',
};

export const pageOptions = [
    { value: 'ofertas', label: 'Ofertas' },
    { value: 'modelos', label: 'Modelos' },
    { value: 'hotsite', label: 'Promocional' },
    { value: 'pilotos', label: 'Pilotos' },
    { value: 'equipes', label: 'Equipes' },
    { value: 'lancamentos', label: 'Lançamentos' },
    { value: 'eventos', label: 'Eventos' },
]

export const ComponentOptions = [
  { value: 'bannerImagemUnica', label: 'Banner imagem única' },
  { value: 'bannerCarrosselLow', label: 'Banner carrossel' },
  { value: 'bannerCarrosselHigh', label: 'Banner carrossel alta escala' },
  { value: 'text', label: 'Texto editável' },
  { value: 'card1', label: 'Card + Texto' },
  { value: 'cardBanner', label: 'Banner com texto' },
  { value: 'textCard', label: 'Texto + Card' },
  { value: 'imageAndVideo', label: 'Imagem e Vídeo' },
  { value: 'card2', label: 'Duas Imagens' },
  { value: 'card3', label: 'Card com 3 itens' },
  { value: 'cardfourimages', label: 'Card com 4 imagens' },
  { value: 'cardImagesDisplay', label: 'Carrossel de imagens' },
  { value: 'videoFull', label: 'Video' },
  { value: 'cardsEvent', label: 'Cards de eventos' },
  { value: 'product', label: 'Cards de produtos' },
  { value: 'newsletter', label: 'Newsletter' },
  { value: 'cardcategory', label: 'Carrossel de categorias' },
  { value: 'review', label: 'Review' },
  { value: 'faqcomponent', label: 'FAQ' },
];
