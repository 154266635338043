import { extendObservable } from 'mobx';
import OrderModel from '../OrderModel';
import SportbayOrderItemModel from './SportbayOrderItemModel';
import SportbayRecomendedItemModel from './SportbayRecomendedItemModel';
import SportbayShippingService from './SportbayShippingService';
import SportbayShippingDetails from './SportbayShippingDetails';
import StatusChangesModel from './SportbayStatusChangesModel';
import PaymentDetailModel from './SportbayPaymentDetailModel';
import OrderStatusModel from './SportbayOrderStatusModel';

class SportbayConciliationModel {
  constructor(data = {}) {
    // this['@class'] = 'br.com.stoom.sportbay.model.SportbayOrder';

    this.merchantName = data.merchantName;
    this.merchantDocument = data.merchantDocument;
    this.orderMerchantId = data.orderMerchantId;
    this.orderId = data.orderId;
    this.statusName = data.statusName;
    this.paymentType = data.paymentType;
    this.createdDate = data.createdDate;
    this.customerFirstName = data.customerFirstName;
    this.customerDocument = data.customerDocument;
    this.orderVersion = data.orderVersion;

    extendObservable(this, {
      status: data.status && new OrderStatusModel(data.status),
    });
  }

  get getAdmin() {
    return this.customer.administrator;
  }
}

export default SportbayConciliationModel;
