/**Status */
export const maritalStatus = [
  {id: 'SOLTEIRO', value: 'SOLTEIRO', label: 'Solteiro(a)'},
  {id: 'CASADO', value: 'CASADO', label: 'Casado(a)'},
  {id: 'VIUVO', value: 'VIUVO', label: 'Viúvo(a)'},
  {id: 'OUTRO', value: 'OUTRO', label: 'Outro'},
];

/**Sexos */
export const genders = [
  {id: 'M', value: 'MASCULINO', label: 'Masculino'},
  {id: 'F', value: 'FEMININO', label: 'Feminino'},
  {id: 'O', value: 'OUTROS', label: 'Outros'},
];

/**Tipos de entidade */
export const entityTypes = [
  {id: 'F', value: 'FISICA', label: 'Física'},
  {id: 'J', value: 'JURIDICA', label: 'Jurídica'},
];

/**Tipos de moeda */
export const currency = [
  {id: 'BRL', value: 'BRL', label: 'R$ - Real'},
];

/**Tipos de categoria */
export const categoryTypes = [
  {id: 'VITRINE', value: 'VITRINE', label: 'Vitrine'},
];

/**Tipos de categoria */
export const comparativeTypes = [
  {id: 'TEXT', value: 'TEXT', label: 'Texto'},
  {id: 'BOOLEAN', value: 'BOOLEAN', label: 'Boolean'}
];

/**Tags para Banner */
export const tagsBanners = [
  {label: 'Slideshow Principal', value: 'SLIDESHOW_PRINCIPAL'}, // Imagens a vontade, pode criar a vontade
  {label: 'Slideshow Principal Mini', value: 'SLIDESHOW'}, // Imagens a vontade, pode criar a vontade
  {label: 'Imagens Home', value: 'IMAGENS_HOME'}, // Regra Esp. abrir drop. selecionar a disposição da imagens com 1, ou mais...até 6 imagens
  {label: 'Imagens Home Mobile', value: 'IMAGENS_HOME_MOBILE'},
  {label: 'Carrinho', value: 'CARRINHO'}, // Até 1 Imagem
  {label: 'Vitrine (PLP)', value: 'VITRINE_PLP'}, // uma imagem, e após selecionar é preciso abrir o dropwdown de categoria, o label
  {label: 'Vitrine (PLP Marca)', value: 'VITRINE_PLP_MARCA'},
  {label: 'Vitrine (PLP Campanha)', value: 'VITRINE_PLP_CAMPANHA'},
  {label: 'Checkout', value: 'CHECKOUT'}, // Até 1 Imagem
  {label: 'Sub-header', value: 'SUB_HEADER'}, // Até 1 imagem
  {label: 'Blog', value: 'BLOG'}, // Até 3 Imagens
  {label: 'Marcas', value: 'MARCAS'},
  {label: 'Banner Full', value: 'BANNER_FULL'}, // Imagens Full unica
  {label: 'Banner Full Mobile', value: 'BANNER_FULL_MOBILE'}, // Imagens Full unica mobile
  {label: 'Sellers', value: 'SELLERS_CARROUSEL'} // Carrousel de sellers na home
];

/**Tags para usar no filtro Banner */
export const tagsFilterBanners = [
  {label: 'Todas', value: 'all'},
  {label: 'Slideshow Principal', value: 'SLIDESHOW_PRINCIPAL'}, // Imagens a vontade, pode criar uma ativa um de cada (responsivo e não responsivo)
  {label: 'Slideshow Principal Mini', value: 'SLIDESHOW'}, // Imagens a vontade, pode criar a vontade
  {label: 'Imagens Home', value: 'IMAGENS_HOME'}, // Até 6 imagens, pode criar umas um de cada (responsivo e não responsivo)
  {label: 'Imagens Home Mobile', value: 'IMAGENS_HOME_MOBILE'},
  {label: 'Carrinho', value: 'CARRINHO'}, // Até 1 Imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  {label: 'Vitrine (PLP)', value: 'VITRINE_PLP'}, // Uma imagem, tem que adicionar categoria, pode criar uma ativa um de cada categoria (responsivo e não responsivo)
  {label: 'Vitrine (PLP Marca)', value: 'VITRINE_PLP_MARCA'},
  {label: 'Vitrine (PLP Campanha)', value: 'VITRINE_PLP_CAMPANHA'},
  {label: 'Checkout', value: 'CHECKOUT'}, // Até 1 Imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  {label: 'Sub-header', value: 'SUB_HEADER'}, // Até 1 imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  {label: 'Blog', value: 'BLOG'}, // Até 3 Imagens, pode criar uma ativa um de cada (responsivo e não responsivo)
  {label: 'Marcas', value: 'MARCAS'},
  {label: 'Banner Full', value: 'BANNER_FULL'}, // Imagens Full unica
  {label: 'Banner Full Mobile', value: 'BANNER_FULL_MOBILE'}, // Imagens Full unica mobile
  {label: 'Sellers', value: 'SELLERS_CARROUSEL'} // Carrousel de sellers na home
];

/**Tipos de categoria com coleção*/
export const categoryTypesWithCollection = [
  {id: 'VITRINE', value: 'VITRINE', label: 'Vitrine'},
  {id: 'COLECAO', value: 'COLECAO', label: 'Coleção'},
];
