import BaseModel from '../BaseModel';
// import SportbayShippingService from './SportbayShippingService';
// import SportbayShippingClass from './SportbayShippingClass';
import SportbayShippingCompany from './SportbayShippingCompany';

// class SportbayShippingDetails extends BaseModel {
//   constructor(data = {}) {
//     super(data);
//     this['@class'] = 'br.com.stoom.sportbay.model.SportbayShippingDetails';
//     this.optionName = data.optionName;
//     this.shippingPrice = data.shippingPrice;
//     this.shippingDiscount = data.shippingDiscount;
//     this.shippingTotal = data.shippingTotal;
//     this.service = new SportbayShippingService(data.service);
//     this.shippingClass = new SportbayShippingClass(data.shippingClass);
//   }
// }

class SportbayShippingDetails extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.sportbay.model.SportbayShippingDetails';
    this.active = data.active;
    this.created = data.created;
    this.deleted = data.deleted;
    this.externalId = data.externalId;
    this.modified = data.modified;
    this.shippingCompany = new SportbayShippingCompany(data.shippingCompany);
    this.shippingDiscount = data.shippingDiscount;
    this.shippingPrice = data.shippingPrice;
    this.shippingTotal = data.shippingTotal;
    this.uuid = data.uuid;
  }
}

export default SportbayShippingDetails;
